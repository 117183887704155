import React from "react";
import Header from "../components/header/header.js";
import { useForm, ValidationError } from '@formspree/react';

const WhyCAU = () => {
  const [state, handleSubmit] = useForm(process.env.GATSBY_FORM_SPREE);
  return (
    <>
      <Header
        title="Perché Connect@You"
        tagTitle="Perché Connect@You - Sistemi Wifi"
        metaDescription="Valorizza il servizio wifi offerto ai  tuoi clienti, ottieni maggiore visibilità all’interno dei social"
        metaKeywords="sistemi wifi"
      />
      <div className="container">
        <div className="columns">
          <div className="column ">
            <h2>Assistenza 24/7</h2>
            <p className="description">
              <br />
              <br />
              Un team di tecnici esperti sarà a tua disposizione per ogni
              necessità.
            </p>
          </div>
        </div>
        <div className="line" />
        <div className="columns">
          <div className="column ">
            <h2>Elevate competenze tecniche</h2>
            <p className="description">
              <br />
              <br />
              Il team di tecnici esperti di Connect@You ti offre un servizio
              trasversale di consulenza gratuita, seguendoti dall’analisi per
              l’individuazione dell’hardware più adatto alle esigenze della tua
              attività, alla fornitura e contestuale installazione dell’hardware
              per giusto utilizzo e corretta gestione di client contemporanei.
            </p>
          </div>
        </div>
        <div className="line" />
        <div className="columns">
          <div className="column ">
            <h2>Monitoraggio Access Point</h2>
            <p className="description">
              <br />
              <br />
              Riceverai un avviso quando uno di essi smetterà di funzionare.
              <br />
              Monitoriamo le infrastrutture di rete dei nostri clienti
              attraverso l’installazione sui nostri server di vps dedicate a
              servizi per i principali brand di tecnologia wireless (UniFi,
              Cambium Networks, Aruba).
              <br /> Attraverso il Back Office anche tu, sarai in grado di
              tenere sotto controllo la tua rete e verificare costantemente
              eventuali malfunzionamenti dei dispositivi installati.
            </p>
          </div>
        </div>
        <div className="line" />
        <div className="columns">
          <div className="column ">
            <h2>Gestione connessioni rete</h2>
            <p className="description">
              <br />
              <br />
              Per ogni utente potrai stabilire la larghezza di banda da offrire,
              in modo da evitare il sovraccarico della tua rete, così facendo
              riuscirai a monitorare il traffico internet.
              <br /> Inoltre, ti offriamo la possibilità di prevedere una lista
              nera, alla quale aggiungere utenti che non potranno più accedere
              alla tua rete.
              <br /> Potrai stabilire per ogni utente un accesso con connessione
              limitata per durata.
              <br /> Scegli di rendere operativo il tuo WiFi secondo fasce
              orarie definite.
            </p>
          </div>
        </div>
        <div className="section" />
        <div className="columns">
          <div className="column">
            <h3>Perché scegliere Connect@You?</h3>
          </div>
        </div>
        <div className="section" />
        <div className="columns">
          <div className="column ">
            <h2>WiFi</h2>
            <p className="description">
              <br />
              Valorizza il servizio WiFi offerto ai tuoi clienti
            </p>
          </div>
          <div className="column ">
            <h2>Plug&amp;Play</h2>
            <p className="description">
              <br />
              Il gateway è Plug&amp;Play e di facile installazione
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column ">
            <h2>Sicuro</h2>
            <p className="description">
              <br />È compliant al nuovo GDPR Regolamento (UE) 2016/679
            </p>
          </div>
          <div className="column ">
            <h2>Social</h2>
            <p className="description">
              <br />
              Ottieni maggiore visibilità all’interno dei social
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column ">
            <h2>Database</h2>
            <p className="description">
              <br />
              Crea il tuo database di contatti
            </p>
          </div>
          <div className="column ">
            <h2>Monetizza</h2>
            <p className="description">
              <br />
              Sviluppa azioni marketing grazie alla profilazione verticale del
              target
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column ">
            <h2>Integrato</h2>
            <p className="description">
              <br />
              Potrai integrare Connect@You con i nostri software
              <br /> di booking engine e brand reputation
            </p>
          </div>
          <div className="column ">
            <h2>Osserva</h2>
            <p className="description">
              <br />
              Monitora le recensioni online e aumenta la tua brand reputation
            </p>
          </div>
        </div>
        <div className="section" />
        <div className="columns">
          <div className="column"><span className="azure ">Richiedi informazioni</span></div>
          <div className="column">
            {  ( state.succeeded) ?  <p className="contact-form-success">Messaggio inviato con successo! Sarai ricontattato a breve.</p>
              :
            <form onSubmit={handleSubmit}>
              <div className="columns">
                <div className="column">
                  <div className="inputBox">
                    <span className="azure">Nome</span>
                    <br />
                    <input
                      name="name" className="input" type="text" placeholder="Nome" required />
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <div className="inputBox">
                    <span className="azure">Email</span>
                    <br />
                    <input
                      name="email" className="input" type="email" placeholder="Email" required/>

                      <ValidationError 
                        prefix="Email" 
                        field="email"
                        errors={state.errors}
                      />
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <div className="inputBox">
                    <span className="azure">Telefono</span>
                    <br />
                    <input
                      name="telephone" className="input" type="text" placeholder="Telefono" required/>

                      <ValidationError 
                        prefix="Telefono" 
                        field="telephone"
                        errors={state.errors}
                      />
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-10">
                  <div className="inputBox">
                    <span className="azure">Attività</span>
                    <br />
                    <input 
                      name="business" className="input" type="text" placeholder="Attività" />
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-8">
                  <div className="inputBox">
                    <span className="azure">Comune</span>
                    <br />
                    <input
                      name="city" 
                      className="input" 
                      type="text" 
                      placeholder="Comune" />
                  </div>
                </div>               
              </div>
              <div className="columns">
                <div className="column">
                  <div className="inputBox">
                    <span className="azure">Messaggio</span>
                    <br />
                    <textarea
                      style={{height: 100}}
                      name="message"
                      className="input"
                      type="text"
                      placeholder="Scrivi un messaggio"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <label className="checkbox">
                    <input type="checkbox" required />
                    <span>Accetto i Termini e Condizioni</span>
                  </label>
                </div>
                <div className="column sendMessage">
                  <button className="button"  type="submit" disabled={state.submitting}>Invia messaggio</button>
                </div>
              </div>
            </form> }
            
          </div>
          
        </div>
        <div className="section"/>
      </div>
    </>
  );
};

export default WhyCAU;
